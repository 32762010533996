<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" :style="blockStyle">
        <div class="categories w-100" v-if="block.groupEnabled">
            <div class="category" v-for="(group, index) in boundItemList" :key="`list-item-category-${index}`">
                <cms-block-list-category :item="group.items[0]" @block-selected="selectBlock" :block="categoryBlock" is-child :editable="editable"/>

                <div class="category-items list-container">
                    <cms-block-selector v-for="item in group.items" :key="`list-item-${item.id}`" class="cms-list-item" :block-component="bodyComponent" no-body :item="item"
                                        @block-selected="selectBlock" :block="listItemBlock" is-child :editable="editable"/>
                </div>
            </div>
        </div>
        <div class="list-container" v-else>
            <cms-block-selector v-for="item in boundItemList" :key="`list-item-${item.id}`" class="cms-list-item" :block-component="bodyComponent" no-body :item="item"
                                 @block-selected="selectBlock" :block="listItemBlock" is-child :editable="editable"/>
        </div>
    </cms-block>
</template>

<script>
import CmsBlockListCategory from "@/components/editor/rendering/blocks/cms-block-list-category.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-list #hot-reload-debug
export default {
    name: `cms-block-list`,
    components: {
        CmsBlockListCategory,
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock,
    computed: {
        listItemBlock() {
            return this.block.children[0];
        },
        categoryBlock() {
            return this.block.children[1];
        },
        bodyComponent() {
            if (this.block.mediaProxy.layout === `cards`) {
                return `b-card`;
            } else {
                return `div`;
            }
        },
        blockStyle() {
            let style = {
                "--list-item-spacing": this.block.mediaProxy.spacing,
                "--list-item-columns": this.block.mediaProxy.columns
            }
            if (this.block.divider.active) {
                style[`--divider-width`] = this.block.divider.width;
                style[`--divider-color`] = this.block.divider.color;
            }
            return style;
        },
        boundItemList() {
            let list = [];
            if (this.block.itemType === `inherit` || this.block.itemType === `universal-search`) {
                list = this.cms.getItemsByType(this.cmsPage.itemType);
            } else {
                list = this.block.itemList;
            }

            if (this.block.sortEnabled) {
                list.sort((a, b) => {
                    let fieldA = a[this.block.sortBy];
                    let fieldB = b[this.block.sortBy];

                    if (typeof fieldA === `object`) {
                        return this.tr(fieldA).localeCompare(this.tr(fieldB));
                    } else if (typeof fieldA === `string`) {
                        return fieldA.localeCompare(fieldB);
                    } else {
                        return fieldB - fieldA;
                    }
                });
                if (this.block.sortOrder === `desc`) {
                    list.reverse();
                }
            }

            if (this.block.groupEnabled) {
                const groups = list.reduce((groups, item) => {
                    const key = item.getFieldFormatted(this.block.groupBy);
                    if (!groups[key]) {
                        groups[key] = {
                            key: key,
                            items: []
                        };
                    }
                    groups[key].items.push(item);
                    return groups;
                }, {});
                list = [];
                for (let key in groups) {
                    list.push(groups[key]);
                }

                list.sort((a, b) => {
                    return a.key - b.key;
                });
                if (this.block.groupOrder === `desc`) {
                    list.reverse();
                }
            }
            return list;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-cms-list {
    --divider-width: 0;
    --divider-color: 0;
    --list-item-columns: 0;
    --list-item-spacing: 10px;

    ::v-deep .cms-block {
        //margin-bottom: var(--list-item-spacing);
        break-inside:avoid;
        display: block;
    }

    .cms-list-item {
        width: 100%;
    }

    &.layout-default {
        ::v-deep .cms-block:not(:last-child) {
            border-bottom: var(--divider-width) solid var(--divider-color);
        }
    }

    //&.layout-cards {
    //    ::v-deep > .cms-container {
    //        display: block;
    //        column-count: var(--list-item-columns);
    //        column-gap: var(--list-item-spacing);
    //        orphans: 1;
    //        widows: 1;
    //        line-height: 1;
    //    }
    //}

    &.layout-cards {
        .list-container {
            padding-top: var(--list-item-spacing);
            padding-bottom: var(--list-item-spacing);
            display: grid;
            grid-template-columns: repeat(var(--list-item-columns), 1fr);
            grid-gap: var(--list-item-spacing);
        }
    }
}
</style>
